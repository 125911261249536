
<template>
  <div class="main-content">
    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-container>
      <b-card  title="Create Rule" class="card-icon mb-30 p-16">

        <form action="#" class="form-horizontal "  method="post" v-on:submit.prevent="validateBeforeSubmitRule">
          <b-row>
            <b-col lg="12" md="12" sm="12">
              <app-timeline>
                <app-timeline-item variant="primary">
                  <div  class="mb-4 bg-primary p-1" >
                    <h5 class="text-white">1. Basic Information</h5>
                  </div>
                  <div >
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label><strong>Rule Name</strong></label> <br/>
                          <input type="text" class="form-control" v-model="rule.ruleName" />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label><strong>Marketing Text</strong></label> <br/>
                          <input type="text" class="form-control" v-model="rule.marketingText" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label><strong>Status</strong></label> <br/>
                          <label for="rule-active">
                            <strong><input id="rule-active" type="radio" class="form-check" v-model="rule.status" value="ACTIVE" /> Active </strong>
                          </label>
                          <label class="ml-5" for="rule-inactive">
                            <strong><input id="rule-active" type="radio" class="form-check" v-model="rule.status" value="INACTIVE" /> Inactive </strong>
                          </label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label><strong>Points Type</strong></label> <br/>
                          <select class="form-control" v-model="rule.pointType">
                            <option value="BONUS"> Bonus </option>
                            <option value="BASE"> Base </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label><strong>Funding Partners</strong></label> <br/>
                        <table class="table table-bordered">
                          <tr>
                            <th>Partner</th>
                            <th>Contribution</th>
                          </tr>
                          <tr v-for="(fundingPartner, index) in rule.partners">
                            <td>
                              <select class="form-control" v-model="fundingPartner.fundingPartnerId">
                                <option value=""> -- Select -- </option>
                                <option :value="partner.id" v-for="partner in partners"> {{ partner.name }}</option>
                              </select>
                            </td>
                            <td>
                              <input type="number" class="form-control" v-model="fundingPartner.share" />
                            </td>
                            <td>
                              <b-button
                                  type="button"
                                  @click="removeFundingPartner(index)"
                                  variant="danger"
                                  class="btn-icon"
                              >
                                <feather-icon icon="ArchiveIcon" />
                              </b-button>
                            </td>
                          </tr>
                        </table>

                        <br/>

                        <button type="button" @click="addPartner" class="btn btn-warning btn-sm float-right">Add Partner</button>
                      </div>
                    </div>
                  </div>
                </app-timeline-item>
                <app-timeline-item variant="secondary">
                  <div class="mb-4 bg-secondary p-1">
                    <h5 class="text-white">2. Rules </h5>
                  </div>

                  <div class="mb-2">

                    <div v-for="(loyaltyRule, index) in rule.rules" >
                          <div class="row" v-if="loyaltyRule.logic">
                            <div class="col-4 offset-4">
                              <div class="rule-wrapper">
                                <div class="rule-line"></div>
                                <div class="rule-wordwrapper">
                                  <div class="rule-word">
                                    <span @click="changeLogic(index, 'OR')" style="cursor: pointer;" v-if="loyaltyRule.logic == 'AND'" class="badge badge-warning divider-text">{{ loyaltyRule.logic }}</span>
                                    <span @click="changeLogic(index, 'AND')" style="cursor: pointer;" v-else class="badge badge-success divider-text">{{ loyaltyRule.logic }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-4">
                            </div>
                          </div>

                          <div class="row p-2" style="border-top: 1px solid #ccc;border-bottom: 1px solid #ccc; ">
                            <div class="col-3">
                              <select v-model="loyaltyRule.ruleType" class="form-control">
                                <option> -- Select -- </option>
                                <option v-for="ruleType in loyaltyRule.ruleTypes" :value="ruleType.key" >{{ ruleType.value }}</option>
                              </select>
                            </div>
                            <div class="col-8">
                              <div v-if="loyaltyRule.ruleType == 'DAYS'">
                                <label style="font-weight: bolder;padding-left: 7px;padding-right: 7px;" v-for="dayOfWeek in days">
                                  <input v-model="loyaltyRule.loyaltyTransactionDayRule.daysAllowed" name="dayOfWeek" type="checkbox" :value="dayOfWeek" > &nbsp;&nbsp; {{ dayOfWeek }}  &nbsp;&nbsp;
                                </label>
                              </div>
                              <div v-if="loyaltyRule.ruleType == 'BRAND'">
                                <div class="mb-2">
                                <span style="padding: 7px;margin-bottom: 5px" v-for="(selectedOutlet,index) in selectedOutlets">
                                  <b-badge variant="info">{{ selectedOutlet.name }} <feather-icon style="color:#000000;cursor: pointer;" @click="removeOutlet(index)" icon="XIcon" /> </b-badge>
                                </span>
                                </div>
                                <b-button class="btn btn-success btn-sm float-right" v-b-modal.add-outlets>Add outlet(s)</b-button>
                              </div>
                              <div v-if="loyaltyRule.ruleType == 'PAYMENT_MODE'">
                                <label><strong>Payment Modes:</strong></label> <br/>
                                <div class="form-group" v-for="paymentMode in paymentModes">
                                  <label  :for="'payment-mode-'+paymentMode">
                                    <strong><input :id="'payment-mode-'+paymentMode" type="checkbox" v-model="loyaltyRule.loyaltyPaymentModeRule.paymentModes" :value="paymentMode.id" /> {{ paymentMode.name }} </strong>
                                  </label>
                                </div>
                              </div>
                              <div v-if="loyaltyRule.ruleType == 'SKU'">
                                <div class="row">
                                  <div class="col-4">
                                    <div class="form-group">
                                      <label>Select Outlet</label> <br/>
                                      <select class="form-control" v-model="outletProduct" @change="getOutletProductsData">
                                        <option value="0"> -- Select -- </option>
                                        <option :value="outlet.id" v-for="outlet in outlets"> {{ outlet.name }}</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-8">
                                    <div class="mb-2">
                                      <span style="padding: 7px;margin-bottom: 5px" v-for="(selectedProduct,index) in selectedProducts">
                                        <b-badge variant="info">{{ selectedProduct.name }} <feather-icon style="color:#000000;cursor: pointer;" @click="removeProduct(index)" icon="XIcon" /> </b-badge>
                                      </span>
                                    </div>
                                    <b-button v-if="products.length > 0" class="btn btn-success btn-sm float-right" v-b-modal.add-products>Add product(s)</b-button>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <div class="col-1">
                              <b-button
                                  @click="remoteRule(index)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="danger"
                                  class="btn-icon"
                              >
                                <feather-icon icon="ArchiveIcon" />
                              </b-button>
                            </div>
                          </div>
                    </div>


                    <div class="mt-3 mb-2" style="clear: both;">
                      <button type="button" @click="addRule('AND')" class="btn btn-warning btn-sm float-right">Add AND Rule</button>
                      <button type="button" @click="addRule('OR')" class="btn btn-success btn-sm float-right mr-3">Add OR Rule</button>
                    </div>
                    <br/>
                  </div>

                </app-timeline-item>
                <app-timeline-item variant="success">
                  <div class="mb-4  bg-success p-1">
                    <h5 class="text-white">3. Points Award </h5>
                  </div>

                  <div>
                    <div class="row mt-2">
                      <div class="col-4">
                        <div class="form-group">
                          <label><strong>Points Expiry (Days)</strong></label> <br/>
                          <input type="text" class="form-control" v-model="rule.pointsExpiryInDays" />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <label><strong>Waiting Period (Days)</strong></label> <br/>
                          <input type="text" class="form-control" v-model="rule.waitingPeriodInDays" />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <label><strong>Redemption Value</strong></label> <br/>
                          <input type="text" class="form-control" v-model="rule.cashValue" />
                        </div>
                      </div>
                    </div>

                    <div class="row ">
                      <div class="col-12">
                        <label><strong>Reward Type</strong></label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <label>
                          <input v-model="rule.pointsAward.pointsAwardStrategy" type="radio" name="rewardType" value="FLAT" > Flat
                        </label>
                      </div>
                      <div class="col-4">
                        <label>
                          <input v-model="rule.pointsAward.pointsAwardStrategy" type="radio" name="rewardType" value="SLAB" > Price Slab
                        </label>
                      </div>
                      <div class="col-4">
                        <label>
                          <input v-model="rule.pointsAward.pointsAwardStrategy" type="radio" name="rewardType" value="MULTIPLIER" > Multiplier
                        </label>
                      </div>
                    </div>

                    <div class="row" v-if="rule.pointsAward.pointsAwardStrategy == 'FLAT'">
                      <div class="col-4">
                        <div class="form-group">
                          <label><strong>Points to award:</strong></label> <br/>
                          <input type="number" class="form-control" v-model="rule.pointsAward.flatPoints" />
                        </div>
                      </div>
                    </div>
                    <div class="row" v-else-if="rule.pointsAward.pointsAwardStrategy == 'MULTIPLIER'">
                      <div class="col-6">
                        <div class="form-group">
                          <label><strong>No. of times:</strong></label> <br/>
                          <input type="number" class="form-control" v-model="rule.pointsAward.multiplyFactor" />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label><strong>Maximum Limit:</strong></label> <br/>
                          <input type="number" class="form-control" v-model="rule.pointsAward.multiplyMaximum" />
                        </div>
                      </div>
                    </div>
                    <div class="row" v-else-if="rule.pointsAward.pointsAwardStrategy == 'SLAB'">
                      <div class="row mt-2">
                        <div class="col-12">
                          <table class="table">
                            <tr>
                              <th>Slab Type</th>
                              <th>Lower Limit</th>
                              <th>Upper Limit</th>
                              <th>For Every X</th>
                              <th>Points</th>
                              <th></th>
                            </tr>
                            <tr v-for="(slab, index) in rule.pointsAward.slabs">
                              <td>
                                <select v-model="slab.pointsAwardSlabType" class="form-control">
                                  <option value="FLAT">Flat</option>
                                  <option value="VARIABLE">Variable</option>
                                </select>
                              </td>
                              <td> <input class="form-control" type="number" v-model="slab.lowerLimit" /></td>
                              <td> <input class="form-control" type="number" v-model="slab.upperLimit" /></td>
                              <td>
                                <span v-if="slab.pointsAwardSlabType == 'VARIABLE'">
                                   <input class="form-control" type="number" v-model="slab.variableRatio" />
                                </span>
                              </td>
                              <td> <input class="form-control" type="number" v-model="slab.points" /></td>
                              <td>
                                <b-button
                                    type="button"
                                    @click="removeSlab(index)"
                                    variant="danger"
                                    class="btn-icon"
                                >
                                  <feather-icon icon="ArchiveIcon" />
                                </b-button>
                              </td>
                            </tr>
                          </table>

                          <br/>
                          <button type="button" @click="addSlab" class="btn btn-warning btn-sm float-right">Add Slab</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </app-timeline-item>
                <app-timeline-item variant="warning">
                  <div class=" mb-4 bg-warning p-1">
                    <h5 class="text-white">4. Limit & Validity</h5>
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-4">
                        <div class="form-group">
                          <label><strong>Capping per user</strong></label> <br/>
                          <input type="text" class="form-control" v-model="rule.cappingPerUser" />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <label><strong>Total Capping</strong></label> <br/>
                          <input type="text" class="form-control" v-model="rule.totalCapping" />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <label><strong>Threshold (Minimum spend)</strong></label> <br/>
                          <input type="text" class="form-control" v-model="rule.threshold" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label><strong>Start Date</strong></label> <br/>
                          <date-picker style="width: 100%;" :disabled-date="disabledValidityStartDates" v-model="rule.validityStartDate" format="YYYY-MM-DD HH:mm"   valueType="format" type="datetime"></date-picker>

                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label><strong>End Date</strong></label> <br/>
                          <date-picker :read-only="rule.validityStartDate" style="width: 100%;" v-model="rule.validityEndDate" :disabled-date="disabledValidityEndDates"  format="YYYY-MM-DD HH:mm" valueType="format" type="datetime"></date-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                </app-timeline-item>
              </app-timeline>

              <div class="mt-2 mb-2" >
                <hr/>
              </div>
              <div >
                <button class="btn btn-success float-right" type="submit" >Submit</button>
              </div>
            </b-col>
          </b-row>
        </form>
      </b-card>
    </b-container>

    <b-modal id="add-outlets" title="Add Outlets" @cancel="true"   centered>
      <div class="form-body">
        <div class="mb-2">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="selectAllRows"
          >
            Select all
          </b-button>
          &nbsp;&nbsp;
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="clearSelected"
          >
            Clear selected
          </b-button>
        </div>

        <br/>

        <b-table
            ref="selectableTable"
            selectable
            :select-mode="selectMode"
            :items="outlets"
            :fields="outlet_columns"
            responsive
            class="mb-0"
            @row-selected="onRowSelected"
        >
          <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
        </b-table>
      </div>
    </b-modal>
    <b-modal id="add-products" title="Add Products" @cancel="true"   centered>
      <div class="form-body">
        <div class="mb-2">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="selectAllProductRows"
          >
            Select all
          </b-button>
          &nbsp;&nbsp;
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="clearSelectedProducts"
          >
            Clear selected
          </b-button>
        </div>

        <br/>

        <b-table
            ref="selectableProductTable"
            selectable
            :select-mode="selectMode"
            :items="products"
            :fields="outlet_columns"
            responsive
            class="mb-0"
            @row-selected="onProductSelected"
        >
          <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css'
  import Multiselect from 'vue-multiselect';
  import vSelect from 'vue-select'
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  import Button from "@/views/components/button/Button.vue";
  import Table from "@/views/table/bs-table/Table.vue";

export default {
  data() {
    return {
      outletProduct:"",
      selectMode: 'multi',
      days:["MON","TUE","WED","THU","FRI","SAT","SUN"],
      disabled:false,
      isLoading: false,
      fullPage: true,
      outlets:[],
      partners:[],
      products:[],
      outlet_columns:["name"],
      ruleTypes:[
          {
            key:"DAYS",
            value:"Transaction Days"
          },
        {
          key:"BRAND",
          value:"Brands"
        },
        {
          key:"SKU",
          value:"Products"
        },
        {
          key:"REGISTRATION",
          value:"On Registration"
        },
        {
          key:"PAYMENT_MODE",
          value:"Payment Mode"
        },
        {
          key:"REFERRAL",
          value:"Referral"
        }
      ],
      paymentModes:[],
      rule:{
        id:0,
        ruleName:"",
        marketingText:"",
        partners:[{
          fundingPartnerId:"",
          share:""
        }],
        cashValue:"",
        status: "ACTIVE",
        pointType:"",
        pointsExpiryInDays: "",
        waitingPeriodInDays: "",
        rulesLogic : [],
        validityStartDate: "",
        validityEndDate:"",
        pointsAward:{
          pointsAwardStrategy:"FLAT",
          flatPoints:0,
          multiplyFactor:0,
          multiplyMaximum:0,
          slabs:[]
        },
        rules:[{
          ruleTypes:[
            {
              key:"DAYS",
              value:"Transaction Days"
            },
            {
              key:"BRAND",
              value:"Brands"
            },
            {
              key:"SKU",
              value:"Products"
            },
            {
              key:"REGISTRATION",
              value:"On Registration"
            },
            {
              key:"PAYMENT_MODE",
              value:"Payment Mode"
            },
            {
              key:"REFERRAL",
              value:"Referral"
            }
          ],
          rulesOrder:1,
          ruleType:"",
          loyaltyOutletRule:{
            outletId:[]
          },
          loyaltySKURule:null,
          loyaltyTransactionDayRule:{
            daysAllowed: []
          },
          loyaltyPaymentModeRule:{
            paymentModes:[]
          },
          logic:""
        }],
        cappingPerUser:0,
        totalCapping: 0,
        threshold: 0
      },
      selectedOutlets:[],
      selectedProducts:[],
      selectedPaymentModes:[]
    };
  },
  components: {
    Table,
    Button,
    AppTimeline,
    AppTimelineItem,
    Loading,
    DatePicker,
    Multiselect,
    vSelect
  },

  mounted (){
    this.getPartnersData();
    this.getOutletsData();
    this.getPaymentModesData();
  },
  computed:{
    selectedRuleTypes() {
     return  this.rule.rules.map(rule => {
        return rule.ruleType
      }).filter(ruleType => {
        if(ruleType) {
          return ruleType;
        }
      });
    }
  },
  methods: {
    ...mapActions(["createRule","getPartners","getOutlets","getOutletProducts","getPaymentModes"]),

    rulesTypeAvailableOptions() {
      let rulesTypeLength = this.rule.rules.length;
      if(rulesTypeLength === 1) {
        return this.ruleTypes;
      } else {
        let self = this;
        return  this.ruleTypes.filter(ruleType => {
          return !self.selectedRuleTypes.includes(ruleType.key);
        });
      }
    },

    disabledValidityEndDates(date){
      const validityEndDate = new Date(this.rule.validityStartDate);
      validityEndDate.setDate(validityEndDate.getDate() + 1);

      return validityEndDate > date ;
    },

    disabledValidityStartDates(date){
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return today > date ;
    },

    validateBeforeSubmitRule() {
      let self = this;
      this.rule.rules.map(function (rule){
        if(rule.ruleType == 'BRAND') {

          let outletIds = self.selectedOutlets.map(function (outlet){
            return outlet.id
          });

          console.log(outletIds)

          rule.loyaltyOutletRule = {}

          rule.loyaltyOutletRule.outletId = outletIds;
        } else if(rule.ruleType == 'SKU') {
          let productIds = self.selectedProducts.map(function (product){
            return product.id
          })

          rule.loyaltySKURule = {
            outletId: self.outletProduct,
            outletProducts: productIds
          }
        }
        return rule;
      });

      this.rule.rulesLogic = this.rule.rules.map(rule => rule.logic).filter(ruleLogic => ruleLogic !== '').toString();

      this.saveRule();
    },

    onProductSelected(items) {
      this.selectedProducts = items
    },
    onRowSelected(items) {
      this.selectedOutlets = items
    },
    addRule(ruleLogic){
      let index = this.rule.rules.length + 1;

      let rule = {
        ruleTypes:[],
        rulesOrder:index,
        ruleType:"",
        loyaltyOutletRule:{
          outletId:[]
        },
        loyaltySKURule:null,
        loyaltyPaymentModeRule:{
          paymentModes:[]
        },
        loyaltyTransactionDayRule:{
          daysAllowed: []
        },
        logic: ruleLogic
      }
      this.rule.rules.push(rule);

      this.rule.rules[this.rule.rules.length - 1].ruleTypes = this.rulesTypeAvailableOptions();
    },
    selectAllProductRows() {
      this.$refs.selectableProductTable.selectAllRows()
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    changeLogic(index, logic){
      this.rule.rules[index].logic = logic;
    },
    remoteRule(index){
      if(this.rule.rulesLogic.length > 0){
        this.rule.rulesLogic.splice(index,1)
      }
      this.rule.rules.splice(index,1)
    },
    clearSelectedProducts() {
      this.$refs.selectableProductTable.clearSelected()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    addSlab(){
      let slab = {
        pointsAwardSlabType:"",
        lowerLimit: "",
        upperLimit:"",
        points: "",
        variableRatio:""
      }
      this.rule.pointsAward.slabs.push(slab);
    },
    addPartner(){
      this.rule.partners.push({
        fundingPartnerId:"",
        share:""
      });
    },
    removeProduct(index) {
      this.selectedProducts.splice(index,1)
    },
    removeOutlet(index) {
      this.selectedOutlets.splice(index,1)
    },
    removeFundingPartner(index) {
      this.rule.partners.splice(index,1)
    },
    removeSlab(index) {
      this.rule.pointsAward.slabs.splice(index,1)
    },
    getOutletProductsData(){
      if(this.outletProduct) {
        let self = this;
        this.isLoading = true;
        this.getOutletProducts({outletId: this.outletProduct})
            .then(function (products) {
              self.isLoading = false;
              self.products = products;
            })
            .catch(function (error) {

            });
      }
    },
    getOutletsData(){
      let self = this;
      this.getOutlets()
        .then(function (outlets) {
          self.outlets = outlets;
        })
        .catch(function (error) {

        })
    },
    getPartnersData(){
      let self = this;
      this.getPartners()
          .then(function (partners) {
            self.partners = partners;
          })
          .catch(function (error) {

          })
    },
    saveRule() {
      let self = this;

      this.isLoading = true;

      this.createRule(this.rule).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Rule saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        this.$router.push("/apps/rules");


      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },
    getPaymentModesData() {
      let self = this;
      this.getPaymentModes()
          .then(function (paymentModes) {
            self.paymentModes = paymentModes;
          })
          .catch(function (error) {

          })
    }
  },

};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.rule-wrapper {
  position: relative;
  width: 136px;
  height: 141px;
  margin: 10px;
}

.rule-line {
  position: absolute;
  left: 49%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #ccc;
  z-index: 1;
}

.rule-wordwrapper {
  text-align: center;
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -12px;
  z-index: 2;
}

.rule-word {
  color: #ccc;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 3px;
  font: bold 12px arial,sans-serif;
  background: #fff;
}

</style>
